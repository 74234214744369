<template>
  <v-container fluid class="px-6 ">
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <h1>
      {{ $t("Archive") }}
    </h1>
    <v-row>
      <v-col>
        <v-select
          id="additional_conditions"
          :items="sortByItems"
          v-model="sortBy"
          solo
          label="Sort By"
          item-text="title"
          item-value="value"
          @change="getArchiveTickets()"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-for="item in items" :key="item.id">
        <v-card class="pa-6 mb-6 main-shadow archive pl-12">
          <v-row no-gutters align="center" justify="space-between">
            <v-col sm="10" class="archive__main-col">
              <i class="blue lighten-1 pa-4 ticket-icon ticket-icon__archive">
                <v-img
                  src="@/assets/icons/expertise.svg"
                  v-if="
                    item.ticket_type === 'diagnostic' &&
                      item.state !== 'warranty_requested' &&
                      item.state !== 'on_warranty'
                  "
                  max-height="64px"
                  max-width="64px"
                ></v-img>
                <v-img
                  src="@/assets/icons/repair.svg"
                  v-if="
                    item.ticket_type !== 'diagnostic' &&
                      item.state !== 'warranty_requested' &&
                      item.state !== 'on_warranty'
                  "
                  max-height="64px"
                  max-width="64px"
                ></v-img>
                <v-img
                  src="@/assets/icons/warranty.svg"
                  v-if="item.state === 'warranty_requested' || item.state === 'on_warranty'"
                  max-height="64px"
                  max-width="64px"
                ></v-img>
              </i>
              <h3>
                {{ item.title }}
              </h3>
              <p>
                <span class="text--disabled">{{ $t("Valid from") }}: </span>
                <span class="pl-4">{{ formatDate(item.valid_from) }}</span>
              </p>
              <p>
                <span class="text--disabled">{{ $t("Valid to") }}: </span>
                <span class="pl-4">{{ formatDate(item.valid_to) }}</span>
              </p>
            </v-col>

            <v-col sm="2" class="px-2">
              <v-btn color="primary" block @click="goToTicket(item.id)">
                {{ $t(`Open`) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center" v-if="pagesLength > 1">
      <v-pagination v-model="page" circle :length="pagesLength"></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { getArchiveTickets } from "@/api";
import { keys } from "lodash";
import moment from "moment";
export default {
  data: () => ({
    page: 1,
    pagesLength: 1,
    items: [],
    loading: false,
    sortBy: "asc",
    sortByItems: [
      { value: "asc", title: "Sort by date ascending" },
      { value: "desc", title: "Sort by date descending" }
    ]
  }),
  methods: {
    getArchiveTickets() {
      this.loading = true;
      getArchiveTickets(this.sortBy, this.page)
        .then(r => {
          this.items = r.data.archive.map(i => {
            const key = keys(i)[0];
            i[key]["id"] = key;
            return i[key];
          });
          console.log(this.items);
          this.pagesLength = this.items.length
            ? Math.ceil(+r.data.pagination.total / +r.data.pagination.per_page)
            : 1;
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => (this.loading = false));
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY  HH:mm");
    },
    goToTicket(id) {
      this.$router.push("/ticket/" + id);
    }
  },
  mounted() {
    this.getArchiveTickets();
  }
};
</script>

<style lang="scss">
.archive {
  padding-left: 60px;
  &__main-col {
    padding-left: 80px !important;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  &::before {
    content: "";
    background-color: #34a7fc;
    height: 100%;
    width: 110px;
    position: absolute;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    top: 0;
    left: 0;
  }
}

.ticket-icon.ticket-icon__archive {
  position: absolute;
  left: 25px;
  top: 33%;
  padding: 3px !important;
  width: 64px;
  height: 64px;
}
</style>
