var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6 ",attrs:{"fluid":""}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('h1',[_vm._v(" "+_vm._s(_vm.$t("Archive"))+" ")]),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"id":"additional_conditions","items":_vm.sortByItems,"solo":"","label":"Sort By","item-text":"title","item-value":"value"},on:{"change":function($event){return _vm.getArchiveTickets()}},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1)],1),_c('v-row',_vm._l((_vm.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-6 mb-6 main-shadow archive pl-12"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"archive__main-col",attrs:{"sm":"10"}},[_c('i',{staticClass:"blue lighten-1 pa-4 ticket-icon ticket-icon__archive"},[(
                  item.ticket_type === 'diagnostic' &&
                    item.state !== 'warranty_requested' &&
                    item.state !== 'on_warranty'
                )?_c('v-img',{attrs:{"src":require("@/assets/icons/expertise.svg"),"max-height":"64px","max-width":"64px"}}):_vm._e(),(
                  item.ticket_type !== 'diagnostic' &&
                    item.state !== 'warranty_requested' &&
                    item.state !== 'on_warranty'
                )?_c('v-img',{attrs:{"src":require("@/assets/icons/repair.svg"),"max-height":"64px","max-width":"64px"}}):_vm._e(),(item.state === 'warranty_requested' || item.state === 'on_warranty')?_c('v-img',{attrs:{"src":require("@/assets/icons/warranty.svg"),"max-height":"64px","max-width":"64px"}}):_vm._e()],1),_c('h3',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t("Valid from"))+": ")]),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(_vm.formatDate(item.valid_from)))])]),_c('p',[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t("Valid to"))+": ")]),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(_vm.formatDate(item.valid_to)))])])]),_c('v-col',{staticClass:"px-2",attrs:{"sm":"2"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.goToTicket(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t("Open"))+" ")])],1)],1)],1)],1)}),1),(_vm.pagesLength > 1)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.pagesLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }